import * as React from 'react';
import { useStore } from 'react-context-hook';
// TODO: Remove
// import useSound from 'use-sound';
import { QuizContext } from '@app/providers/quiz.store';
import { Button, Color, StyledContainer } from '@atomic';
import { QuizResponseFooter } from './response-footer.component';
// TODO: Remove
// eslint-disable-next-line @typescript-eslint/no-var-requires
// const rightAnswerSound = require('@assets/sounds/right-answer-sound.mp3');
// eslint-disable-next-line @typescript-eslint/no-var-requires
// const wrongAnswerSound = require('@assets/sounds/wrong-answer-sound.mp3');
export var Footer = function (_a) {
    var _b;
    var mutateAnswerQuestion = _a.mutateAnswerQuestion, isMutateAnswerLoading = _a.isMutateAnswerLoading, resetAnswerQuestion = _a.resetAnswerQuestion, answerResponse = _a.answerResponse, tarefa = _a.tarefa;
    // TODO: Remove
    // const [isMute] = useStore<boolean>('mute');
    // const [playRightAnswerSound] = useSound(rightAnswerSound);
    // const [playWrongAnswerSound] = useSound(wrongAnswerSound, { volume: 0.5 });
    var _c = React.useContext(QuizContext), quizzes = _c[0], currentQuizIndex = _c[1];
    var choosedAlternativeId = useStore('choosedAlternativeId')[0];
    var isFinalQuestion = (quizzes === null || quizzes === void 0 ? void 0 : quizzes.length) - 1 === currentQuizIndex;
    var _d = React.useState(undefined), points = _d[0], setPoints = _d[1];
    // TODO: Remove
    // if (!isMute && answerResponse) {
    // answerResponse?.data?.acertou ? playRightAnswerSound() : playWrongAnswerSound();
    // }
    var handleConfirmAnswerClick = function () {
        resetAnswerQuestion();
        mutateAnswerQuestion({ quizAlternativaId: choosedAlternativeId });
    };
    React.useEffect(function () {
        if (quizzes[0].pontos) {
            if (quizzes[0].perguntaRespondida) {
                setPoints(quizzes[0].pontos / 2);
            }
            else {
                setPoints(quizzes[0].pontos);
            }
        }
    }, [quizzes]);
    return (React.createElement(React.Fragment, null,
        React.createElement(StyledContainer, { withPadding: true, bgColor: answerResponse
                ? ((_b = answerResponse === null || answerResponse === void 0 ? void 0 : answerResponse.data) === null || _b === void 0 ? void 0 : _b.acertou) ? Color.RightAnswerGreenBg
                    : Color.WrongAnswerRedBg
                : 'inherit' }, answerResponse ? (React.createElement(QuizResponseFooter, { tarefa: tarefa, answerResponse: answerResponse, resetAnswerQuestion: resetAnswerQuestion, isFinalQuestionBtn: isFinalQuestion, currentQuiz: quizzes[currentQuizIndex] })) : (React.createElement(Button, { expanded: true, onClick: handleConfirmAnswerClick, loading: isMutateAnswerLoading, disabled: !choosedAlternativeId, style: {
                backgroundColor: 'rgba(0, 132, 37, 1)',
                borderColor: 'rgba(0, 132, 37, 1)',
                height: '42px',
            } }, "CONFIRMAR")))));
};
